import React from 'react'
import Modal from "react-modal"
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import "../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';

const DateModal = (props) => {

    const {
        open,
        setOpen,
        state,
        setState,
        sendReport,
        setEmployee,
        employee,
    } = props

    console.log(employee)
  return(
<Modal
        isOpen={open}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"40px",
            borderRadius: "none",
            width: "auto",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
<FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    
                    setOpen(false)
                    setState( [ {
                        startDate: moment().startOf("month").toDate(),
                        endDate: moment().endOf("month").toDate(),
                        key: 'selection'
                      }])
                      setEmployee(null)
 
                     }}
              />
        { open && <div className="modal task-modal" >
               

          <h1 className="header" style={{width:"100%", marginTop:"1rem"}}>Select Date range for {employee?.label}</h1>
          <div className="form-group-container task-form" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>       
           
          {
            <div>

<DateRange
  editableDateInputs={true}
  onChange={item => setState([item.selection])}
  moveRangeOnFirstSelection={false}
  ranges={[state]}
/> 
 </div>

          }
            
          </div>
          <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
            <div></div>
            <div
              className={`${state?.startDate && state?.endDate ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={state?.startDate && state?.endDate ? () => {
                setOpen(false)
                sendReport(state)
                setEmployee(null)
  alert(`report sent to you email inbox`)
              } : null }
              style={{height:"100%", width:"100%", padding:"0 20px"}}
            >
     
Report from {moment(state?.startDate).format("DD/MM")} to {moment(state?.endDate).format("DD/MM")}

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default DateModal
       