import React, { useEffect, useState } from "react";
import "./adminSideBar.scss"

import { faBriefcase, faChartLine, faFile, faProjectDiagram, faShieldAlt, faUserFriends, faCertificate, faTimes, faBars, faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
useHistory, useLocation,
} from "react-router-dom";
import copy from "copy-to-clipboard";  
import { useDispatch, useSelector } from "react-redux";
import sideLogo from "../../../assests/images/sideLogo.svg"
import ACTION_BACK_ROUTE_RESET from "../../../actions/route/ACTION_BACK_ROUTE_RESET";
import ACTION_RESET_SIDE_BAR from "../../../actions/ActiveSideBar/ACTION_RESET_SIDE_BAR";
import logout from "../../../assests/images/logout.svg"
import search from "../../../assests/images/search.svg"
import arrow from "../../../assests/images/arrow.svg"
import arrowDown from "../../../assests/images/arrowDown.svg"
import community from "../../../assests/images/community.svg"
import department from "../../../assests/images/department.svg"
import teams from "../../../assests/images/teams.svg"
import PhoneNumber from "../../reusable/addClientModal/Number";
import Dropdown from "react-dropdown";


import {useLazyQuery, useMutation} from "@apollo/client"

import moment from "moment"

import allClientsQuery from "../../../graphql/queries/client/allClientsQuery";
import addUrlMutation from "../../../graphql/mutations/url/addUrlMutation";

import ACTION_USER_NOT_AUTHENTICATED from "../../../actions/user/Authenticated/ACTION_USER_NOT_AUTHENTICATED"
import addLoginMutation from "../../../graphql/mutations/user/addLoginMutation";
import addLogoutMutation from "../../../graphql/mutations/user/addLogoutMutation";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import DateModal from "../../reusable/dateModal";
const AdminSideBar = (props) => {


  //   const [
  //       addLogin,
  //       { loading: addLoginLoding, data: addLoginData },
  //     ] = useMutation(addLoginMutation); 
  //     const [
  //       addLogout,
  //       { loading: addLogoutLoding, data: addLogoutData },
  //     ] = useMutation(addLogoutMutation);

      
 
  // const staffSignIn = () => {
  //   dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  //   const input = {}
  //   input.login = moment().format("MMMM DD, YYYY HH:mm:ss")
  //   input.date = moment().format("MMMM DD, YYYY")
  //   input.user = user.user._id
  //   addLogin({
  //     variables:{
  //       ...input
  //     }
  //   }).then(res => {
  //     console.log(res)
  //     currentLoginRefetch().then(() => {
  //       dispatch(ACTION_LOADING_SPINNER_RESET())
  //     })
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }

  // const staffSignOut = () => {
  //   dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  //   const input = {}
  //   input.logout = moment().format("MMMM DD, YYYY HH:mm:ss")
  //   input._id = currentLoginData.currentLogin[currentLoginData.currentLogin.length - 1]._id
  //   addLogout({
  //     variables:{
  //       ...input
  //     }
  //   }).then(res => {
  //     currentLoginRefetch().then(() => {
  //       dispatch(ACTION_LOADING_SPINNER_RESET())
  //     })
  //   })
  // }


  // useEffect(() => { 
  //   if(currentLoginData) {
  //     dispatch(ACTION_LOADING_SPINNER_RESET())
  //   }

  // },[currentLoginData])


      
    const {
        path,
        user,
        searchClient,
        handleLogout,
        currentLoginData,
        currentLoginRefetch,
        allSales
    } = props

    const dispatch = useDispatch()
    const location = useLocation()
    const activeSideBar = useSelector((state) => state.activeSideBar.active);


    const history = useHistory()
    const [activeRoute, setActiveRoute] = useState("dashboard")
    const [sidebarList, setSideBarList] = useState([
        {
            icon: search,
            name: "Search",
        },
        {
            icon: community,
            name: "Community",
            path: "campaign",
        },
        {
          icon: teams,
          name: "Dashboard",
          path: "dashboard",
      },
        {
          icon: department,
          name: "Manage",
          path: "manage",
      }
    ])
    const [sidebarDownList, setSideBarDownList] = useState([
     
        
    ])

    // useEffect(() => {
    //   setActiveRoute(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    // }, [location]);
    
    const [reload, setReload] = useState(false)


    const [clientSearch, setClientSearch] = useState("")

    const [state, setState] = useState([
      {
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("month").toDate(),
        key: 'selection'
      }
    ]);
    const [to, setTo] = useState(null)
  

    const [ 
        allClients,
        {
          data: data,
          called: called,
          loading: loading,
          refetch: refetch
        },
      ] = useLazyQuery(allClientsQuery , {
        fetchPolicy: "no-cache",
      });

    const sendReport = (s) => {
      console.log(s)
        allClients({
            variables :{
                name: moment(s?.startDate).add(3, "hours").startOf("day").toDate(),
                phoneNumber:  moment(s?.endDate).endOf("day").toDate(),
                email: user.user.email,
                employee : employee.value !== "All" ? employee.value : null
            }
        })
        setState( [ {
          startDate: moment().startOf("month").toDate(),
          endDate: moment().endOf("month").toDate(),
          key: 'selection'
        }])
    }


    
const zeroPad = (num) => {
  var str = String(num); 
  if (str.length < 2) {
    return '0' + str;
  }

  return str;
}

// assuming your time strings will always be (H*:)(m{0,2}:)s{0,2} and never negative
const totalTimeString = (timeStrings) => {
  var totals = timeStrings.reduce(function (a, timeString) {
    var parts = timeString.split(':');
    var temp;
    if (parts.length > 0) {
      temp = Number(parts.pop()) + a.seconds;
      a.seconds = temp % 60;
      if (parts.length > 0) {
        temp = (Number(parts.pop()) + a.minutes) + ((temp - a.seconds) / 60);
        a.minutes = temp % 60;
        a.hours = a.hours + ((temp - a.minutes) / 60);
        if (parts.length > 0) {
          a.hours += Number(parts.pop());
        }
      }
    }

    return a;
  }, {
    hours: 0,
    minutes: 0,
    seconds: 0
  }); 

  // returned string will be HH(H+):mm:ss
  return [
    zeroPad(totals.hours),
    zeroPad(totals.minutes),
    zeroPad(totals.seconds)
  ].join(':');
}



    const [
        addUrl,
        { data: addUrlData },
      ] = useMutation(addUrlMutation)
    

    const addUrlFunction = () => {
        addUrl({
            variables:{
                active: true
            }
        }).then((res) => {       
             console.log(res)

            alert(`https://emsbody.fit/admin/signup/${res.data.addUrl._id}`)
            copy(`https://emsbody.fit/admin/signup/${res.data.addUrl._id}`)
        })
    }
    const [openDate, setOpenDate] = useState(false)
    
    const [employee, setEmployee] = useState(null)
    
 
    return (
        <div className={`${ activeSideBar ? "mobileSideBar sidebarContainer" : "sidebarContainer"} ${reload ? "reload" : "reloaded"}`}>

          <DateModal  
          open={openDate}
          setOpen={setOpenDate}
          state={state[0]}
          setState={setState}
          sendReport={sendReport}
          setEmployee={setEmployee}
          employee={employee}
          />
            <FontAwesomeIcon
                className="activeSideBarX"
                icon={faBars}
                onClick={() => dispatch(ACTION_RESET_SIDE_BAR())}
              />
            <div className="sidebarChildren">
                <div className="sidebarLogo">
                    <div className="accountInformation">
                        <div className="sideName">{user && user.user.name}</div>
                        {user && !user?.user?.role?.includes("admin") && currentLoginData && currentLoginData.currentLogin.length > 0 && (
                  <>
                  {/* <div

                      className="branchLogin"
                      onClick={ currentLoginData.currentLogin[currentLoginData.currentLogin.length -1].totalTime !== null ? staffSignIn : staffSignOut}
                    >{ currentLoginData.currentLogin[currentLoginData.currentLogin.length -1].totalTime !== null ? (<span className="loginSpan" style={{color:"#AAFF00"}}>Branch login</span>) : (<span style={{color:"#FF5733"}} className="logoutSpan">Branch logout</span>)}
              </div> */}

              </>
              )}
              {/* {user && !user?.user?.role?.includes("admin") && currentLoginData && currentLoginData.currentLogin.length == 0 && (
                <>
              <div
                className="branchLogin"
                onClick={staffSignIn}
              ><span className="loginSpan" style={{color:"#AAFF00"}}>
                 Branch login</span>
              </div>
              </>
              )} */}
                    </div>

                </div> 
                <div className="sideLine"></div>
                <div className="sidebarContent">
                    
                    {sidebarList.map((route, i) => (

                        <>
                        {i == 1 && (
                        <div className="sideLine"></div>
                    )}

                    {route.name == "Search" ? (
                     <PhoneNumber searchClient={searchClient} type="side" />            
                    ) : (
<div key={route.name} className={`routeContainer ${route.name == "Search" && "sideSearch"} ${ route.path && activeRoute == route.path ? "activeRoute" : "false"}`}
                        onClick={() => {
                            route.path && setActiveRoute(route.path)
                            route.active = !route.active
                            history.push({
                                pathname: `${path}/${route.path}`,
                            })
                            setReload(!reload)
                        }}>
                            <img
                                        className="routeIcon"
                                        src={route.icon}
                            />
                            <div className="routeName">
                                {route.name}
                            </div>
                           { route.arrow && <img
                                        className="routeIcon forceRight"
                                        src={route.active ? arrowDown : arrow}
                            />}

                    </div>
                    )}
                        
                    
                    {i == 1 && (
                        <div className="sideLine"></div>
                    )}
                    
                    </>
                    ))}
                    {user && allSales ? 
                    <div className="form-row" style={{width:"100%"}}>
                    <div className="form-collection" style={{width:"100%"}}>
                    <div className="form-label">
                          Type
                        </div>
                        <Dropdown
                        menuClassName="underMenu"
                          key="name"
                          options={ user?.user?.role == "admin" || user?.user?.email == "rawnaa.rona21@gmail.com" ? [{label:"All Staff", value:"All"}, ...allSales] : [...allSales.filter(x => x.value == user.user._id)]}
                          onChange={(choice) => {
                            setEmployee(choice)
                            setOpenDate(true)
                            console.log(employee)
                          }} 
                          value={employee?.value}
                          controlClassName="form-input"
                          
                        />
                  </div>
                  </div>
                    : null }
                </div>
                <div>
                { user && user.user.role == "admin" && <div className="auth-button" style={{padding:"0 .5rem"}} onClick={() => {
                   addUrlFunction()
                }}>Generate signup</div>}

</div>

                <div className="sidebarContent" style={{height:"fit-content"}}>
                    
                    {sidebarDownList.map((route, i) => (
                        <>
                        <div key={route.name} className={`routeContainer  ${ route.path && activeRoute == route.path ? "activeRoute" : "false"}`}
                        onClick={() => {
                            route.path && setActiveRoute(route.path)
                            dispatch(ACTION_BACK_ROUTE_RESET())
                            route.path && history.push(`${path}/${route.path}`)
                            dispatch(ACTION_RESET_SIDE_BAR())
                        }}>
                            <img
                                        className="routeIcon"
                                        src={route.icon}
                            />
                            <div className="routeName">
                                {route.name}
                            </div>
                           { route.arrow && <img
                                        className="routeIcon forceRight"
                                        src={arrow}
                            />}

                    </div>
                    </>
                    ))}
                </div>
                 <div className="sidebarContent" style={{height:"fit-content", marginTop:"1rem", position:"absolute", bottom:"10px", left:0}}>                  
                        <div className={`routeContainer logoutH`}
                        style={{display:"flex", justifyContent:"center", alignItems:"center", background:"#ABB0B9", borderRadius:"8px"}}
                        onClick={() => {
                            handleLogout()
                        }}>
                            
                            <div className="routeName" style={{marginTop:"0px", fontWeight:"600"}}>
                                Log Out
                            </div>
                            <img
                                        className="routeIcon"
                                        src={logout}
                            />
                        </div>            

              {/* { user && !user?.user?.role?.includes("admin") && currentLoginData && currentLoginData.currentLogin.length > 0 && currentLoginData.currentLogin[currentLoginData.currentLogin.length - 1].totalTime !== null? (
                    <p className="large_screen_side_menu_copyright">
                    <b style={{color:"black"}}>{currentLoginData.currentLogin.map(x => x.totalTime).reduce((a, c) => totalTimeString([a, c]))}</b> Hours Total login today
                </p>
                ) : (
                <p className="large_screen_side_menu_copyright">
                    &copy; {new Date().getFullYear()} EMSBODYFITCARIOEG
                </p>
                )} */}

                    
                </div>
            </div>
        </div>
    )
}

export default AdminSideBar